import Glide from "@glidejs/glide";

export default function initSliders() {
  let sliders = document.querySelectorAll(".slider");

  if (sliders == "" || typeof sliders == "undefined" || sliders.length == 0) {
    return;
  }

  sliders.forEach((sliderItem) => {
    let options = {
      type: "carousel",
      autoplay:false
    };

    if (sliderItem.classList.contains("home-slider")) {
      options = {
        ...options,
        type: "carousel",
        autoplay: 4000,
        animationDuration:3000,
        animationTimingFunc: 'ease-in-out'
      };
    }

    if (sliderItem.classList.contains("home-advices-content-slider")) {
      options = {
        ...options,
        type: "carousel",
        autoplay: false,
        perView: 3,
        peek: { before: 100, after: 0 },
        gap: 20,
        breakpoints: {
          ...options.breakpoints,
          1440: { perView: 2, peek: { before: 100, after: 0 } },
          969: { perView: 1.2, peek: { before: 0, after: 0 } },
          575: { perView: 1.2, peek: { before: 0, after: 0 } },
        },
      };
    }

    if (sliderItem.classList.contains("home-actualities-content-slider")) {
      options = {
        ...options,
        type: "carousel",
        autoplay: false,
        perView: 3,
        peek: { before: 0, after: 100 },
        gap: 20,
        breakpoints: {
          ...options.breakpoints,
          1440: { perView: 2, peek: { before: 0, after: 100 } },
          969: { perView: 1.2, peek: { before: 0, after: 0 } },
          575: { perView: 1.2, peek: { before: 0, after: 0 } },
        },
      };
    }

    if (sliderItem.classList.contains("home-realizations-slider")) {
      options = {
        ...options,
        type: "carousel",
        autoplay: false,
        perView: 1,
      };
    }

    if (
      sliderItem.querySelectorAll(".glide__slide").length < options.perView &&
      window.matchMedia("(min-width: 969px)").matches
    ) {
      options.type = "slider";
      sliderItem.classList.add("disabled");
    }

    let slider = new Glide(sliderItem, options);

    //Désactivation du slider si un seul élément
    slider.on("mount.before", function () {
      if (
        sliderItem.querySelectorAll(".glide__slide").length < options.perView &&
        window.matchMedia("(min-width: 969px)").matches
      ) {
        slider.update({
          rewind: false,
        });
        slider.disable();
      }
    });

    slider.mount();
  });
}

document.addEventListener('DOMContentLoaded', function() {
  initSliders();
});