class BurgerMenu
{
    constructor()
    {
        let menu = document.querySelector('.burger-menu-wrapper');
        let openTrigger = document.querySelector('.header-menu');
        let closeTrigger = document.querySelector('.burger-menu-title');

        if (!menu || !openTrigger || !closeTrigger)
        {
            return;
        }

        this.menu = menu;
        this.openTrigger = openTrigger;
        this.closeTrigger = closeTrigger;

        this.initEvents();
    }

    initEvents()
    {
        let ref = this;

        this.openTrigger.addEventListener('click', function()
        {
            ref.openMenu();
        });

        this.closeTrigger.addEventListener('click', function()
        {
            ref.closeMenu();
        })
    }

    openMenu()
    {
        this.menu.classList.add('open');
    }

    closeMenu()
    {
        this.menu.classList.remove('open');
    }
}

!(function () {
    new BurgerMenu();
})();